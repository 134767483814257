import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import stablecoinBull from "../../images/tronpedia/topic-pages/stablecoin-bull.png";

const Stablecoin = ({data, location}) => {

    const pageData = {
        title: "Stablecoin",
        description: "Stability… in crypto?? This section covers stablecoins, their importance in the digital landscape, and the biggest providers of them. ",
        img: stablecoinBull,
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia Stablecoin | TRONDAO</title>
                <meta name="description"
                      content=" A stablecoin is a digital asset that maintains a stable value. Discover what they are, why they're important, and how you can acquire your own."/>
                <meta property="og:description"
                      content=" A stablecoin is a digital asset that maintains a stable value. Discover what they are, why they're important, and how you can acquire your own."/>
                <meta property="og:title" content="TRONpedia Stablecoin | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={data} location={location} pageData={pageData}/>
        </>
    );
};


export default Stablecoin;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle (
    filter: {tronpedia_article_info: {category: {eq: "STABLECOIN"}}}
  ) {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
           category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
